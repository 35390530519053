import { TranslationProvider } from '@/src/contexts/translation.context';
import { TranslationService } from '@/src/services/translation.service';
import React, { useState } from 'react';
import { PageProps } from 'gatsby';

import { Helmet } from 'react-helmet';
import Header from '@/src/components/header/header';
import useAuthStatus from '@/src/hooks/auth-status.hook';
import * as styles from './faq.module.scss';
import FaqInnerContent from '@/src/components/faq-box/faq-inner-content';

export default function FaqPage(pageProps: PageProps) {
  const [translation] = useState(new TranslationService(pageProps.pageContext));
  const { isAuthenticated } = useAuthStatus();

  return (

    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate('faq.title')}</title>
        <meta name="description" content={translation.translate('faq.description')} />
        <meta http-equiv="content-language" content={translation.lang} />
        <html className="unauthenticated"></html>
      </Helmet>

      <Header unauthenticated={!isAuthenticated} pageContext={pageProps.pageContext}></Header>
      <div className="unauthenticated__wrapper">
        <div className="unauthenticated__inner unauthenticated__inner--xl">
          <div className="inner">
            {<FaqInnerContent pageContext={pageProps.pageContext}></FaqInnerContent>}
          </div>
        </div>
      </div>

      <div className="decoration-circle-wrapper">
        <div className="decoration-circle">
        </div>
      </div>
    </TranslationProvider>
  );
};